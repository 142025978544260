<style scoped>
.field-title {
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.681);
}

.field-content {
  font-size: 14px;
  color: #000000;
  font-weight: 700;
}

.bank-statement-row:hover {
  background-color: rgba(169, 169, 169, 0.147);
}

.requested-tag {
  margin-left: 140px;
}
</style>
<template>
  <div>
    <div class="tab_header d-flex black--text font-weight-bold px-2 mb-1">
      <div
        style="width: 75%"
        class="d-flex align-center py-3"
        @click="toggleAccordion()"
      >
        <div class="text-body-1 font-weight-medium px-3">
          Bank Statements
        </div>
        <span
          class="text-body-2 requested-tag font-weight-bold secondary_2--text"
          v-if="is_bank_statement_required"
        >
          <v-icon class="secondary_2--text mr-1">
            mdi-alert-circle-outline </v-icon
          >Requested
        </span>
      </div>
      <div style="width: 25%" class="d-flex align-center justify-end">
        <v-btn
          small
          outlined
          color="success"
          class="font-weight-bold mr-2"
          @click="getUploadedDocuments"
          :disabled="isButtonDisabled"
        >
          Verify All
        </v-btn>
        <v-btn
          small
          outlined
          color="secondary_2"
          class="font-weight-bold"
          :disabled="btn_loader || is_bank_statement_required"
          :loading="btn_loader"
          @click="openRequestAgainReasonDialog()"
        >
          Request Again
        </v-btn>
        <v-btn
          fab
          text
          x-small
          class="mx-2"
          outlined
          depressed
          v-if="getPermission('Refresh Request')"
          :disabled="!is_bank_statement_required"
          @click="openResetConfirmationDialog()"
        >
          <v-icon color="black">
            mdi-reload
          </v-icon>
        </v-btn>
        <v-icon
          color="black"
          class="mx-3"
          v-if="show"
          @click="toggleAccordion()"
        >
          mdi-chevron-up
        </v-icon>
        <v-icon color="black" class="mx-3" v-else @click="toggleAccordion()">
          mdi-chevron-down
        </v-icon>
      </div>
    </div>

    <div v-if="show" class=" pb-2">
      <DivLoader
        v-if="loader"
        :loading_type="'list-item-two-line, list-item-two-line'"
      ></DivLoader>
      <v-row
        v-else
        v-for="(item, index) in sorted_bank_statements"
        :key="item.id"
        class="ma-0 px-6 bank-statement-row"
      >
        <v-col cols="4" class="pl-0 d-flex">
          <div class="field-content mr-2">{{ index + 1 }}.</div>
          <div>
            <p class="field-content">
              {{
                item.document_type
                  .replace(/_/g, " ")
                  .toLowerCase()
                  .split(" ")
                  .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                  .join(" ")
              }}
              <span style="color:grey">({{ getDate(item.created) }})</span>
            </p>
            <p
              v-if="item.document_password"
              class="primary_2--text font-weight-bold text-caption"
            >
              <span>
                <v-icon small class="success--text">
                  mdi-shield-key
                </v-icon>
              </span>
              {{ item.document_password }}
            </p>
            <p class="error--text text-caption">
              {{ item.rejection_reason }}
            </p>
          </div>
        </v-col>
        <v-col cols="3" class="d-flex align-center justify-center">
          <span
            class="text-body-2 ml-2 font-weight-bold success--text"
            v-if="item.document_status == 'approved'"
          >
            <v-icon class="success--text mr-1">
              mdi-check-circle-outline </v-icon
            >Verified
          </span>
          <span
            class="text-body-2 ml-2 font-weight-bold secondary_2--text"
            v-else-if="item.document_status == 'requested'"
          >
            <v-icon class="secondary_2--text mr-1">
              mdi-alert-circle-outline </v-icon
            >Requested
          </span>
          <span
            v-else-if="item.document_status == 'rejected'"
            class="text-body-2 ml-2 font-weight-bold error--text"
          >
            <v-icon class="error--text mr-1"> mdi-close-circle-outline</v-icon
            >Rejected
          </span>
          <span v-else></span>
        </v-col>
        <v-col cols="5" class="d-flex justify-end align-center pr-0">
          <span
            v-if="
              item.document_status != 'approved' &&
                item.document_status != 'rejected'
            "
          >
            <v-btn
              outlined
              small
              color="success"
              class="font-weight-bold mr-2"
              v-if="item.document_status != 'requested'"
              @click="updateDocumentStatus(item.id, 'approved')"
              :disabled="btn_loader"
              :loading="btn_loader"
            >
              Verify
            </v-btn>
            <v-btn
              outlined
              small
              color="error"
              :disabled="btn_loader"
              :loading="btn_loader"
              class="font-weight-bold mx-2"
              v-if="item.document_status != 'requested'"
              @click="openRejectionReasonDialog(item)"
            >
              <!-- @click="updateDocumentStatus(item.id, 'rejected')" -->
              Reject
            </v-btn>
          </span>
          <v-btn
            small
            text
            class="indigo lighten-5 py-4"
            @click="
              isImageDocument(item.document)
                ? previewImage(item.document, item.document_type)
                : viewDocument(item.document)
            "
          >
            <v-icon color="deep-purple darken-4">mdi-eye-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <RejectionReasonDialog
      v-if="rejection_reason_dialog_data.flag"
      :dialog="rejection_reason_dialog_data"
      @recallBankDetails="getBankDetails()"
    />
    <RequestAgainReasonDialog
      v-if="request_again_reason_dialog_data.flag"
      :dialog="request_again_reason_dialog_data"
      @recallBankDetails="getBankDetails()"
    />
    <ResetConfirmationDialog
      ref="reset_confirmation_dialog"
      @refresh="getBankDetails()"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      sorted_bank_statements: [],
      show: true,
      bank_statements: [],
      loader: false,
      btn_loader: false,
      // document_id: null,
      is_bank_statement_required: false,
      rejection_reason_dialog_data: {
        flag: false,
        reject_pre_verification: false,
        document_id: null,
        document_type: "",
      },
      request_again_reason_dialog_data: {
        flag: false,
        document_type: "",
      },
      uploaded_documents: [],
      isButtonDisabled: false,
    };
  },
  components: {
    DivLoader: () => import("@/components/ui/DivLoader"),
    RejectionReasonDialog: () =>
      import("@/components/pre-verification/dialog/RejectionReasonDialog"),
    RequestAgainReasonDialog: () =>
      import("@/components/pre-verification/dialog/RequestAgainReasonDialog"),
    ResetConfirmationDialog: () =>
      import("@/components/pre-verification/dialog/ResetConfirmation"),
  },
  computed: {
    ...mapGetters({
      active_loan_id: "pre_verification/getActiveLoanId",
      customer_id: "pre_verification/getCustomerId",
    }),
  },
  mounted() {
    this.getBankDetails();
    // this.disableVerifyAllBtn();
  },
  watch: {},
  methods: {
    openRejectionReasonDialog(value) {
      this.rejection_reason_dialog_data.flag = true;
      this.rejection_reason_dialog_data.reject_pre_verification = false;
      this.rejection_reason_dialog_data.document_id = value.id;
      this.rejection_reason_dialog_data.document_type = value.document_type;
    },
    toggleAccordion() {
      this.show = !this.show;
      if (this.show) {
        this.getBankDetails();
      }
    },
    // view document
    viewDocument(url) {
      window.open(url, "_blank");
    },

    // returning true if image extension is png, jpg or, jpeg
    isImageDocument(url) {
      let ext = url.split(".");
      ext = ext[ext.length - 1];
      return ext == "png" || ext == "jpg" || ext == "jpeg" || ext == "webp";
    },
    // if isImageDocument returns true then opens image in new tab
    previewImage(url, document_name) {
      let image_url = url;

      let route = this.$router.resolve({
        name: "view-image",
        params: {
          image: this.encript(image_url),
          document: this.encript(document_name),
        },
      });
      window.open(route.href, "_blank");
    },
    documentChecker() {
      let doc = this.bank_statements.find(
        (item) => item.document_status === "approved"
      );
      if (doc) {
        this.$store.dispatch("pre_verification/setBankStatementStatus", true);
      } else {
        this.$store.dispatch("pre_verification/setBankStatementStatus", false);
      }
      let doc2 = this.bank_statements.find(
        (item) => item.document_status === "uploaded"
      );
      if (doc2) {
        this.$store.dispatch(
          "pre_verification/setBankStatementUploadStatus",
          true
        );
      } else {
        this.$store.dispatch(
          "pre_verification/setBankStatementUploadStatus",
          false
        );
      }
    },
    sortArrayByDate() {
      this.sorted_bank_statements = this.bank_statements.sort(function(a, b) {
        return new Date(a.created).getTime() - new Date(b.created).getTime();
      });
    },
    getDate(item) {
      let dateObj = new Date(item);
      let year = dateObj.getFullYear();
      let Month = dateObj.getMonth() + 1;
      let date = dateObj.getDate();
      let formatted_date = date + "-" + Month + "-" + year;
      return formatted_date;
    },
    getBankDetails() {
      const self = this;
      self.loader = true;
      self.btn_loader = true;
      let params = {
        customer_id: this.customer_id,
      };
      const successHandler = (response) => {
        if (response.data.success) {
          self.bank_statements = response.data.bank_statement;
          this.sortArrayByDate();
          self.is_bank_statement_required =
            response.data.is_bank_statement_required;
          this.documentChecker();
          this.disableVerifyAllBtn();
        }
      };
      const finallyHandler = () => {
        self.btn_loader = false;
        self.loader = false;
      };

      self.request_GET(
        self,
        self.$urls.NEW_CUSTOMER_BANK_DETAILS,
        params,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
    updateDocumentStatus(document_id, action) {
      const self = this;
      self.btn_loader = true;

      let form = new FormData();
      form.append("document_id", document_id);
      form.append("document_status", action);
      form.append("rejection_reason", "");

      const successHandler = (response) => {
        if (response.data.success) {
          this.getBankDetails();
          // this.disableVerifyAllBtn();
        }
      };
      const finallyHandler = () => {
        self.btn_loader = false;
      };
      self.request_POST(
        self,
        self.$urls.NEW_CUSTOMER_DOCUMENT_VERIFICAION,
        form,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
    getUploadedDocuments() {
      for (let item of this.bank_statements) {
        if (
          item.document_status === "uploaded" &&
          item.document_status !== "rejected"
        ) {
          this.uploaded_documents.push(item.id);
        }
      }
      this.verifyAllDocuments();
    },
    disableVerifyAllBtn() {
      let countOfStatus = 0;
      for (let item of this.bank_statements) {
        if (
          item.document_status == "rejected" ||
          item.document_status == "approved"
        ) {
          countOfStatus += 1;
        }
      }
      if (countOfStatus == this.bank_statements.length) {
        this.isButtonDisabled = true;
      }
    },
    verifyAllDocuments() {
      const self = this;
      self.btn_loader = true;

      let form = {
        document_status: "approved",
        document_id_list: this.uploaded_documents,
      };

      const successHandler = (response) => {
        if (response.data.success) {
          this.getBankDetails();
          this.uploaded_documents = [];
          this.isButtonDisabled = true;
        }
      };
      const finallyHandler = () => {
        self.btn_loader = false;
      };
      self.request_POST(
        self,
        self.$urls.NEW_CUSTOMER_DOCUMENT_VERIFICAION,
        form,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
    openRequestAgainReasonDialog() {
      this.request_again_reason_dialog_data.flag = true;
      this.request_again_reason_dialog_data.document_type = "bank_statement";
    },
    openResetConfirmationDialog() {
      this.$refs.reset_confirmation_dialog.openResetConfirmation(
        "bank_statement"
      );
    },
  },
};
</script>
